import { useTranslate } from "@tolgee/react";
import { ContactForCareRecipient } from "../../api/generated/graphql";
import { Type, contactContacted } from "../../typewriter/segment";
import { Pill } from "@frontend/lyng/pill/pill";

type Props = {
  contact: Partial<ContactForCareRecipient>;
  style?: "contact" | "careRecipient";
};

export const ContactCard = ({ contact, style = "contact" }: Props) => {
  const { t } = useTranslate();
  const gradient =
    style === "careRecipient"
      ? "bg-gradient-to-br from-contact-card-carerecipient-start via-contact-card-carerecipient-mid to-contact-card-carerecipient-end bg-opacity-40"
      : "bg-gradient-to-br from-contact-card-left to-contact-card-right bg-opacity-40";

  const pills = [];
  if (contact.isPrimary)
    pills.push(
      <Pill
        key="primary"
        text={t("contacts.primary").toString()}
        color="yellow"
      />,
    );
  if (contact.isPayer)
    pills.push(
      <Pill key="payer" text={t("contacts.payer").toString()} color="yellow" />,
    );
  if (contact.hasPowerOfAttorney)
    pills.push(
      <Pill
        key="attorney"
        text={t("contacts.attorney").toString()}
        color="yellow"
      />,
    );

  const type =
    style === "careRecipient"
      ? t("careRecipient")
      : t(
          `relationshipTypes.${contact?.relationshipType?.toLocaleLowerCase()}`,
        );

  return (
    <div className="rounded-md bg-opacity-60 bg-gradient-to-br from-contact-card-left to-contact-card-right p-[2px]">
      <div className={`rounded-t-md bg-red-400 px-4 py-6 ${gradient}`}>
        <div className="flex flex-1 flex-row">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-wrap gap-2">
              {contact?.relationshipType && (
                <div className="flex-grow">
                  <p className="text-label-primary">{type}</p>
                </div>
              )}
              {pills.length !== 0 && (
                <div className="flex flex-wrap gap-2 ">{pills}</div>
              )}
            </div>
            <p className="text-xl font-bold text-label-primary">{`${contact.firstName} ${contact.lastName}`}</p>
            <p className="text-label-primary">{contact.phone}</p>
          </div>
        </div>
      </div>

      {contact.phone && (
        <div className="flex flex-row overflow-hidden rounded-b-md">
          <a
            href={`sms:${contact.phone}`}
            onClick={() =>
              contactContacted({
                type: Type.SMS,
              })
            }
            className="flex-1 bg-main py-3 text-center font-medium text-label-primary"
          >
            {t("contacts.sms")}
          </a>

          <a
            href={`tel:${contact.phone}`}
            onClick={() =>
              contactContacted({
                type: Type.Phone,
              })
            }
            className="flex-1 bg-gradient-to-r from-selected-btn-bg-left to-selected-btn-bg-right py-3 text-center font-bold text-label-primary"
          >
            {t("contacts.call")}
          </a>
        </div>
      )}
    </div>
  );
};
