import * as Sentry from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";
import { ForgotPassword, ForgotPasswordDone } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { SignIn } from "./SignIn";
import { AuthenticatorLayout } from "./AuthenticatorLayout";
import { Error } from "../common/Error";
import { TrackingFunctions } from "./Authenticator";

export type AppType = "mobile" | "office";

export const authenticatorRouter = (
  app: AppType,
  trackingFunctions: TrackingFunctions,
) => {
  const sentryCreateBrowserRouter: typeof createBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  return sentryCreateBrowserRouter([
    {
      path: "/",
      element: <AuthenticatorLayout />,
      errorElement: (
        <div className="h-screen w-screen">
          <Error />
        </div>
      ),
      children: [
        {
          path: "/forgot-password",
          element: <ForgotPassword app={app} />,
        },
        {
          path: "/forgot-password-done",
          element: <ForgotPasswordDone app={app} />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "/*?",
          element: <SignIn app={app} trackingFunctions={trackingFunctions} />,
        },
      ],
    },
  ]);
};
