import React, { FunctionComponent, memo } from "react";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as DefaultApolloProvider,
  ApolloLink,
  Operation,
  NextLink,
  NormalizedCacheObject,
  HttpLink,
} from "@apollo/client";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

type Props = {
  uri: string;
  tokenRequest: () => Promise<string | Error>;
  onStartOperation: (operation: Operation) => void;
  onErrorResponse: (errorResponse: ErrorResponse | null) => void | undefined;
  children: (client: ApolloClient<NormalizedCacheObject>) => React.ReactNode;
};

const withTokenRequest = (tokenRequest: () => Promise<string | Error>) =>
  setContext(async () => {
    return tokenRequest().then((tokenResponse) => {
      if (tokenResponse) {
        return { token: tokenResponse };
      }
      return { token: null };
    });
  });

const authMiddleware = new ApolloLink(
  (operation: Operation, forward: NextLink) => {
    const { token } = operation.getContext();
    if (token) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("tenantId")) {
        localStorage.setItem("tenantId", searchParams.get("tenantId") || "");
      }

      const tenantId = localStorage.getItem("tenantId");
      operation.setContext(() => ({
        headers: {
          Authorization: `Bearer ${token}`,
          "X-TENANT": tenantId || "",
          "X-APP": "mobile",
        },
      }));
    }
    return forward(operation);
  },
);

const errorLink = (
  onErrorResponse: (errorResponse: ErrorResponse | null) => void | undefined,
) => {
  return onError((response: ErrorResponse) => onErrorResponse(response));
};

const startOperationLink = (onStartOperation: (operation: Operation) => void) =>
  new ApolloLink((operation: Operation, forward: NextLink) => {
    onStartOperation(operation);
    return forward(operation);
  });

const cache = new InMemoryCache();

const httpLink = (uri: string) =>
  new HttpLink({
    uri,
  });

export class SharedClient {
  private static client: ApolloClient<NormalizedCacheObject>;

  public static getOrInstanciate = (
    uri: string,
    tokenRequest: () => Promise<string | Error>,
    onStartOperation: (operation: Operation) => void,
    onErrorResponse: (errorResponse: ErrorResponse | null) => void | undefined,
  ) => {
    if (!SharedClient.client) {
      SharedClient.client = new ApolloClient({
        cache,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "network-only",
          },
          query: {
            fetchPolicy: "network-only",
          },
        },
      });
    }

    SharedClient.client.setLink(
      ApolloLink.from([
        startOperationLink(onStartOperation),
        withTokenRequest(tokenRequest),
        errorLink(onErrorResponse),
        authMiddleware.concat(httpLink(uri)),
      ]),
    );

    return SharedClient.client;
  };

  public static getClient() {
    if (!SharedClient.client) {
      throw new Error("client is undefined");
    }
    return SharedClient.client;
  }
}

const ApolloProvider: FunctionComponent<Props> = ({
  uri,
  tokenRequest,
  onStartOperation,
  onErrorResponse,
  children,
}: Props) => {
  const client = SharedClient.getOrInstanciate(
    uri,
    tokenRequest,
    onStartOperation,
    onErrorResponse,
  );
  return (
    <DefaultApolloProvider client={client}>
      {children(client)}
    </DefaultApolloProvider>
  );
};

export default memo(ApolloProvider);
