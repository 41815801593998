import classNames from "classnames";
import { match } from "ts-pattern";

type Size = "m" | "s" | "xs" | "xxs";

interface Props {
  size?: Size;
  variant?: "primary" | "secondary" | "tertiary" | "critical";
  id?: string;
  className?: string;
  htmlFor?: string;
  children: React.ReactNode;
}

export const Label = ({
  size = "m",
  variant = "primary",
  className,
  id,
  htmlFor,
  children,
}: Props) => {
  const sizeClass = match(size)
    .with("m", () => "text-lg")
    .with("s", () => "text-base")
    .with("xs", () => "text-sm")
    .with("xxs", () => "text-xs")
    .exhaustive();

  const fontWeightClass = match(size)
    .with("m", () => "font-medium")
    .with("s", () => "font-medium")
    .with("xs", () => "font-medium")
    .with("xxs", () => "font-normal")
    .exhaustive();

  const fontClass = match(size)
    .with("m", () => "font-poppins")
    .with("s", () => "font-poppins")
    .with("xs", () => "font-poppins")
    .with("xxs", () => "font-inclusive")
    .exhaustive();

  const textClass = match(variant)
    .with("primary", () => "text-greyscale-900 dark:text-greyscale-100")
    .with("secondary", () => "text-greyscale-700 dark:text-greyscale-300")
    .with("tertiary", () => "text-greyscale-600 dark:text-greyscale-400")
    .with("critical", () => "text-critical-800 dark:text-critical-400")
    .exhaustive();

  return (
    <label
      id={id}
      className={classNames(
        sizeClass,
        fontClass,
        fontWeightClass,
        textClass,
        className,
      )}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};
