import classNames from "classnames";

type Props = {
  firstName?: string;
  lastName?: string;
  img?: string;
  backgroundColor?: string;
  size?: "sm" | "md";
};

export const Avatar = ({
  firstName = "",
  lastName = "",
  img,
  backgroundColor = "bg-primary-90",
  size = "md",
}: Props) => {
  const text =
    firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase() ||
    "-";

  const sizeStyles = size === "sm" ? "h-6 w-6" : "h-8 w-8";

  const fontStyles =
    size === "sm"
      ? "text-xs font-normal text-greyscale-900"
      : "text-sm font-medium text-greyscale-900";

  return (
    <div
      className={classNames(
        "flex flex-col justify-center items-center overflow-hidden rounded-full px-[7.5px] py-2",
        backgroundColor,
        sizeStyles,
      )}
    >
      {img ? (
        <img
          src={img}
          alt={text}
          className="h-full w-full rounded-full object-cover"
        />
      ) : (
        <p className={fontStyles}>{text}</p>
      )}
    </div>
  );
};
