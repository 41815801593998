import classNames from "classnames";
import { match } from "ts-pattern";
import { NumberPillColors } from "./types";

type Props = {
  count: number;
  color: NumberPillColors;
  className?: string;
};

export const NumberPill = ({ count, color, className }: Props) => {
  const bgColor = match(color)
    .with("redSubdued", () => "bg-critical-300 dark:bg-critical-900")
    .with("red", () => "bg-critical-400 dark:bg-critical-800")
    .with("yellow", () => "bg-accent-400 dark:bg-accent-400")
    .with("gray", () => "bg-greyscale-100 dark:bg-greyscale-700")
    .exhaustive();

  const textColor = match(color)
    .with("yellow", () => "text-greyscale-900")
    .otherwise(() => "text-greyscale-900 dark:text-greyscale-100");

  if (count === 0) return;

  return (
    <div
      className={classNames(
        "min-w-7 h-7 rounded-full flex justify-center items-center px-1.5 text-base font-medium ",
        bgColor,
        className,
        textColor,
      )}
    >
      {count > 99 ? "99+" : count}
    </div>
  );
};
