import classNames from "classnames";
import { AriaAttributes } from "react";
import { match } from "ts-pattern";

type size = "m" | "s" | "xs" | "xxs";
type type = "primary" | "secondary" | "critical";

type Props = {
  size?: size;
  type?: type;
  className?: string;
  id?: string;
  children: React.ReactNode;
  role?: string;
} & AriaAttributes;

export const Paragraph = ({
  size = "m",
  type = "primary",
  id,
  className,
  children,
  ...rest
}: Props) => {
  const sizeClass = match(size)
    .with("m", () => "text-lg")
    .with("s", () => "text-base")
    .with("xs", () => "text-sm")
    .with("xxs", () => "text-xs")
    .exhaustive();

  const typeClass = match(type)
    .with("primary", () => "text-greyscale-900 dark:text-greyscale-100")
    .with("secondary", () => "text-greyscale-700")
    .with("critical", () => "text-critical-800 dark:text-critical-400")
    .exhaustive();

  return (
    <p
      id={id}
      className={classNames(
        className,
        sizeClass,
        typeClass,
        "font-normal font-inclusive whitespace-break-spaces",
      )}
      {...rest}
    >
      {children}
    </p>
  );
};
